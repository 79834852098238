import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {LayoutConfigService, SubheaderService} from "../../../../../../core/_base/layout";
import {LayoutUtilsService, MessageType} from "../../../../../../core/_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {NgxPermissionsService} from "ngx-permissions";
import {Location} from "@angular/common";
import {Actions, ofType} from "@ngrx/effects";
import {ClientModel, ClientType, EkAuthProvider} from "../../../../../../core/ek-e-commerce/ek-models/client.model";
import {Update} from "@ngrx/entity";
import * as ClientActions from "../../../../../../core/ek-e-commerce/ek-actions/client.actions";
import {CustomerUpdatedSuccessfully} from "../../../../../../core/ek-e-commerce/ek-actions/client.actions";
import {EkClientEmailComponent} from "../ek-client-email/ek-client-email.component";
import {ClientsService} from "../../../../../../core/ek-e-commerce/ek-services/clients.service";
import {
    selectClientById,
    selectClientsActionLoading
} from "../../../../../../core/ek-e-commerce/ek-selectors/client.selector";
import {EkClientAddress} from "../../../../../../core/ek-e-commerce/ek-models/ek-client-address";

@Component({
    selector: 'kt-ek-customer-edit',
    templateUrl: './ek-customer-edit.component.html',
    styleUrls: ['./ek-customer-edit.component.scss']
})
export class EkCustomerEditComponent implements OnInit {

    // Public properties
    customer: ClientModel
    ;
    customerId$: Observable<number>;
    oldCustomer: ClientModel
    ;
    selectedTab = 0;
    loading$: Observable<boolean>;
    addressesSubject$ = new BehaviorSubject<EkClientAddress []>([]);
    customerForm: FormGroup;
    hasFormErrors = false;
    businessType: 'ENTREPRISE' | 'PARTICULIER';
    PERMISSIONS = ['ALL_PRODUCT', 'UPDATE_PRODUCT']
    // Private properties
    private subscriptions: Subscription[] = [];
    addingOrder = false;
    routerLink: string = "";
    // : TODO changer le nom de l'attribut customerState -> le state c un autre attribut remplasser le state par blocked soit dans les attribut ou les méthode
    email: string;

    customerStatus: string;
    modeEditClient: boolean = false;

    newFolder = false;
    private PHONE_REGEX = /^(00213|\+213|0)(5|6|7)(\s*?[0-9]\s*?){5,8}$/;
    currentRole: string = '';
    clientExist: boolean = false;

    clientIsWaiting = false;
    isNewClient = false;
    saveError = "";
    public clientSubject$: BehaviorSubject<ClientModel> = new BehaviorSubject<ClientModel>(null);
    customer$ = this.clientSubject$.asObservable();

    /**
     * Component constructor
     *
     * @param activatedRoute
     * @param router
     * @param userFB
     * @param dialog
     * @param subheaderService
     * @param layoutUtilsService
     * @param store
     * @param layoutConfigService
     * @param ngxPermissionService
     * @param customersService
     * @param cdr
     * @param location
     * @param clientsService
     * @param clientsService
     * @param clientsService
     * @param clientsService
     * @param clientsService
     * @param clientsService
     * @param clientsService
     * @param clientsService
     * @param clientsService
     * @param clientsService
     * @param _actions$
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private userFB: FormBuilder,
                private dialog: MatDialog,
                private subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                private ngxPermissionService: NgxPermissionsService,
                private customersService: ClientsService,
                private cdr: ChangeDetectorRef,
                private location: Location,
                private _actions$: Actions,) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }


    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((params: any) => {
            this.newFolder = params['newFolder'] ? params['newFolder'] : false;
        });

        // if (this.router.url == '/ecommerce/addOrder/customers/add')
        //     this.changeToClientSelection();

        this.loading$ = this.store.pipe(select(selectClientsActionLoading));

        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.modeEditClient = true;
                this.clientExist = true
                this.customer$ = this.store.select(selectClientById(id));
                this.store.pipe(select(selectClientById(id))).subscribe(result => {
                    if (result == undefined) {
                        this.loadCustomerFromService(id);
                        return;
                    }
                    this.loadCustomer(result);
                });
            } else {
                this.clientExist = false
                this.customer = new ClientModel
                ();
                this.customer.clear();
                this.addressesSubject$.next(this.customer.addresses);
                this.oldCustomer = Object.assign({}, this.customer);
                this.initUser();
            }
        });
        // this.businessType = this.customer.type;
        this.subscriptions.push(routeSubscription);

    }

    loadCustomer(_customer, fromService: boolean = false) {
        if (!_customer) {
            // this.goBack('');
        }
        this.customer = _customer;
        this.customerStatus = this.customer.clientStatus;
        this.clientSubject$.next(_customer);
        this.customerId$ = of(_customer.id);
        this.oldCustomer = Object.assign({}, _customer);
        this.initUser();
        if (fromService) {
            this.cdr.detectChanges();
        }
    }

    loadCustomerFromService(customerId) {
        this.customersService.getById(customerId).subscribe(res => {
            this.loadCustomer(res, true);
        });
    }

    sentEmailToClient() {

        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = '400px';
        dialogConfig.width = '400px';

        const dialogRef = this.dialog.open(EkClientEmailComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                if (data.description && this.customer.id) {
                    this.customersService.sendMail(this.customer.id, data.description, data.file).subscribe(res => {

                        if (res) {
                            this.layoutUtilsService.showActionNotification("Email envoyé avec succès!", MessageType.Update, 10000, true, true);
                        } else {
                            this.layoutUtilsService.showActionNotification("Erreur dans l'envoie de l'email", MessageType.Update, 10000, true, true);
                        }
                    });
                }
            }
        );
    }

    /**
     * Init user
     */
    initUser() {
        this.createForm();
        if (!this.customer.id) {
            this.subheaderService.setTitle('Create user');
            this.subheaderService.setBreadcrumbs([
                {title: 'Customer Management', page: `customer-management`},
                {title: 'Customers', page: `customer-management/customers`},
                {title: 'Create customer', page: `customer-management/customers/add`}
            ]);
            return;
        }
        this.subheaderService.setTitle('Edit customer');
        this.subheaderService.setBreadcrumbs([
            {title: 'Customer Management', page: `customer-management`},
            {title: 'Customers', page: `user-management/customers`},
            {title: 'Edit customer', page: `customers-management/customers/edit`, queryParams: {id: this.customer.id}}
        ]);
    }

    /**
     * Create form
     */
    createForm() {
        if (this.customer.id) {
            this.customerForm = this.userFB.group({
                //username: [this.customer.username, Validators.required],
                password: [this.customer.password],
                firstname: [this.customer.firstname, Validators.required],
                lastname: [this.customer.lastname, Validators.required],
                email: [this.customer.email, Validators.compose([Validators.email, Validators.required])],
                activity: [this.customer.activity],
                gender: [this.customer.gender],
                phone: [this.customer.phone, Validators.compose([Validators.minLength(10), Validators.pattern(this.PHONE_REGEX), Validators.required])],
                type: [this.customer.type],
                articleNumber: [this.customer.articleNumber],
                registerNumber: [this.customer.registerNumber],
                taxNumber: [this.customer.taxNumber],
                state: [this.customer.state, Validators.required],
                clientStatus: [this.customer.clientStatus, Validators.required],
                reasonForBlocking: [this.customer.reasonForBlocking],
                //remark: [this.customer.remark]
            });
            return;
        } else {
            this.customerForm = this.userFB.group({
                //username: [this.customer.username, Validators.required],
                password: [this.customer.password],
                firstname: [this.customer.firstname, Validators.required],
                lastname: [this.customer.lastname, Validators.required],
                email: [this.customer.email, Validators.compose([Validators.required, Validators.email])],
                activity: [this.customer.activity],
                gender: [this.customer.gender],
                phone: [this.customer.phone, Validators.compose([Validators.minLength(10), Validators.pattern(this.PHONE_REGEX), Validators.required])],
                type: [this.customer.type],
                articleNumber: [this.customer.articleNumber],
                registerNumber: [this.customer.registerNumber],
                taxNumber: [this.customer.taxNumber],
                state: [this.customer.state, Validators.required],
                clientStatus: [this.customer.clientStatus, Validators.required],
                reasonForBlocking: [this.customer.reasonForBlocking],
                //remark: [this.customer.remark]
            });

            this.customerForm.get('clientStatus').setValue('WAITING')
        }
    }


    /**
     * Redirect to list
     *
     */
    goBackWithId() {
        const url = `/ek-ecommerce/customers`;
        this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});
    }


    /**
     * Refresh user
     * @param isNew
     * @param id
     */
    refreshUser(isNew: boolean = false, id = 0) {

        let url = this.router.url;
        this.clientIsWaiting = false;
        this.isNewClient = false;

        console.log("isNew",isNew)
        //update client
        if (!isNew) {

            if (this.newFolder) {

                console.log(this.customerForm.controls.clientStatus.value)
                if (this.customerForm.controls.clientStatus.value === 'WAITING') {
                    this.clientIsWaiting = true;
                    return;
                }

                this.router.navigateByUrl('ek-ecommerce/folder/add/' + this.customer.id + '?step=1');
                return;
            }

            this.router.navigate([url], {relativeTo: this.activatedRoute});
            return;
        }


        if (this.newFolder) {

            console.log("this.customer.clientStatus", this.customer.clientStatus)
            if (this.customer.clientStatus === 'WAITING') {
                this.clientIsWaiting = true;
                return;
            }

            this.router.navigateByUrl('ek-ecommerce/folder/add/' + id + '?step=1');
            return;
        }

        url = `/ek-ecommerce/customers/edit/${id}`;
        this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});


    }


    /**
     * Reset
     */
    reset() {
        this.customer = Object.assign({}, this.oldCustomer);
        this.createForm();
        this.hasFormErrors = false;
        this.customerForm.markAsPristine();
        this.customerForm.markAsUntouched();
        this.customerForm.updateValueAndValidity();
    }

    /**
     * Save data
     *
     * @param withBack
     */
    onSumbit(withBack: boolean = false) {

        this.hasFormErrors = false;
        const controls = this.customerForm.controls;

        /** check form */
        if (this.customerForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched());

            this.hasFormErrors = true;
            this.selectedTab = 0;
            return;
        }


        const editedUser = this.prepareCustomer();


        if (editedUser.id > 0 && this.currentRole !== 'ROLE_POS_EK' && this.currentRole !== 'ROLE_COMMERCIAL_POS_EK') {
            this.updateUser(editedUser, withBack);
            return;
        }

        this.addUser(editedUser, withBack);

        if (this.newFolder && editedUser.clientStatus === 'WAITING') {
            this.clientIsWaiting = true;
            return;
        }
    }

    /**
     * Returns prepared data for save
     */
    prepareCustomer(): ClientModel {
        const controls = this.customerForm.controls;
        const _customer = new ClientModel();
        _customer.clear();
        _customer.addresses = this.addressesSubject$.value;
        _customer.id = this.customer.id;
        //_customer.username = controls.username.value;
        _customer.password = controls.password.value;
        _customer.email = controls.email.value;
        _customer.firstname = controls.firstname.value;
        _customer.lastname = controls.lastname.value;
        _customer.activity = controls.activity.value;
        _customer.gender = controls.gender.value ? controls.gender.value : null;
        _customer.phone = controls.phone.value;
        _customer.state = controls.state.value;
        _customer.enabled = this.customer.enabled;
        //_customer.remark = controls.remark.value;
        _customer.clientStatus = controls.clientStatus.value;
        _customer.reasonForBlocking = controls.reasonForBlocking.value;
        if (this.businessType == 'ENTREPRISE') {
            _customer.type = ClientType.ENTREPRISE;
            _customer.articleNumber = controls.articleNumber.value;
            _customer.registerNumber = controls.registerNumber.value;
            _customer.taxNumber = controls.taxNumber.value;
        } else {
            _customer.type = ClientType.PARTICULIER;
        }
        return _customer;
    }

    /**
     * Add User
     *

     * @param _customer
     * @param withBack
     */
    addUser(_customer: ClientModel, withBack: boolean = false) {

        _customer.id = undefined;
        _customer.provider = EkAuthProvider.Local;
        _customer.enabled = true;
        _customer.clientStatus = this.currentRole === 'ROLE_POS_EK' || this.currentRole === 'ROLE_COMMERCIAL_POS_EK'? 'ACTIVE' : 'WAITING';

        this.isNewClient = false;
        this.clientIsWaiting = false;

        this.customersService.saveCustomerByAdmin(_customer).subscribe({
            next: (res) => {
                this.customer.id = res.id;


                if (this.currentRole !== 'ROLE_POS_EK' && this.currentRole !== 'ROLE_COMMERCIAL_POS_EK') {


                    if (res.clientStatus === 'WAITING') {

                        //source : from folder creation
                        if (this.newFolder) {

                            this.clientIsWaiting = true;
                            this.isNewClient = true;

                            // send activation mail ...
                            this.customersService.sendEmailActivation(this.customer.id).subscribe();

                            const message = `merci d'activer le client avant continuer le processus du creation !`;
                            this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 5000, true, true);

                            const url = `/ek-ecommerce/customers/edit/${this.customer.id}?newFolder=true`;
                            this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});

                        }else{

                            //source : from client creation
                            const url = `/ek-ecommerce/customers/edit/${this.customer.id}`;
                            this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});

                        }

                        return;
                    }

                }

                const message = `New customer successfully has been added.`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, true);

                if (withBack) {
                    this.goBackWithId();
                } else {
                    this.refreshUser(true, this.customer.id);
                }
            },
            error: (error) => {
                if (error.error.message === 'Client already exists with this Email.') {
                    console.log('Email error ...')
                    this.saveError = 'Un client existe déjà avec cet email.';
                }

                if (error.error.message === 'Client already exists with this phone number.') {
                    console.log('Phone error ...')
                    this.saveError = 'Un client existe déjà avec cet numéro de téléphone.';
                }
            }

        })

        // this.subscriptions.push(addSubscription);
    }

    /**
     * Update user
     *
     * @param _customer
     * @param withBack
     */
    updateUser(_customer: ClientModel
        , withBack: boolean = false) {

        this.clientIsWaiting = false;
        this.isNewClient = false;

        // Update Customer
        if (_customer.clientStatus === 'WAITING') {
            _customer.enabled = false;
        } else if (_customer.clientStatus === 'ACTIVE') {
            _customer.enabled = true;
        }
        _customer.provider = this.oldCustomer.provider;
        const updatedCustomer: Update<ClientModel> = {
            id: _customer.id,
            changes: _customer
        };

        this.store.dispatch(ClientActions.CustomerUpdated({customer: _customer, partialCustomer: updatedCustomer}));

        //customer updated successfully
        this._actions$
            .pipe(ofType(CustomerUpdatedSuccessfully))
            .subscribe((data: any) => {

                this.loading$ = this.store.pipe(select(selectClientsActionLoading));

                if (this.currentRole !== 'ROLE_SUPERADMIN' && this.currentRole !== 'ROLE_ADMIN' && _customer.clientStatus === 'WAITING') {
                    this.clientIsWaiting = true;
                    return;
                }

                const message = `Customer successfully has been saved.`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
                if (withBack) {
                    this.goBackWithId();
                    this.refreshUser(false);

                } else {
                    this.refreshUser(false);
                }

            });

    }

    /**
     * Returns component title
     */
    getComponentTitle() {
        let result = 'Nouveau client';

        if (!this.customer || !this.customer.id) {
            return result;
        }

        result = `Modification du client - ${this.customer.lastname} ${this.customer.firstname}`;

        this.clientSubject$.subscribe(value => {
            result = `Modification du client - ${value.lastname} ${value.firstname}`;
        });

        return result;
    }

    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            if (!hasPermission) {
                this.customerForm.disable();
            }
        });
    }

    /**
     * Close Alert
     *
     * @param $event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    generateGuestMail() {
        const firstname = this.customerForm.value.firstname.replace(' ','');
        const lastname = this.customerForm.value.lastname.replace(' ','');

        if (firstname.length === 0 || lastname.length === 0) {
            this.customerForm.controls.firstname.markAsTouched();
            this.customerForm.controls.lastname.markAsTouched();
            const message = `Vous devez Saisir le Nom et le Prenom.`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, false);
        } else {

            const email = this.generateEmail(firstname, lastname);
            this.customerForm.controls.email.setValue(email);
            this.cdr.detectChanges();

            // this.customersService.getGuestMail(
            //     this.customerForm.value.firstname,
            //     this.customerForm.value.lastname)
            //     .subscribe(res => {
            //         this.customerForm.controls['email'].setValue(res['body']);
            //         this.cdr.detectChanges()
            //     });
        }
    }

    generateEmail(clientFirstName: string, clientLastName: string): string {
        const emailDomain = 'wissalgroup.com';
        // Generate a random number between 100 and 999
        const randomNumber = Math.floor(Math.random() * 900) + 100;
        const generatedEmail = `${clientFirstName}${clientLastName}${randomNumber}@${emailDomain}`;
        return generatedEmail;
    }

    //client blocked => reason For blocking is required
    changeCustomerState(selected) {

        this.customerStatus = selected.value;

        if (!this.customer.id) {

            switch (this.customerStatus) {
                case 'ACTIVE':
                    this.customerForm.controls['password'].setValidators([Validators.required]);
                    break;
                default:
                    this.customerForm.controls['password'].clearValidators();
                    break;
            }
            this.customerForm.controls['password'].updateValueAndValidity();
        }

        if (this.customerStatus === 'BLOCKED') {

            this.customerForm.controls['reasonForBlocking'].setValidators([Validators.required]);

        } else {

            this.customerForm.controls['reasonForBlocking'].clearValidators();
        }

        this.customerForm.controls['reasonForBlocking'].updateValueAndValidity();
    }

    goBack() {
        this.location.back()
    }

    emailOublie() {

        if (this.customer.clientStatus == 'ACTIVE') {
            this.customersService.resetPasswordRequest(this.customer.email).subscribe();
            const message = `L'email à envoyer`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, false);
        } else {
            const message = `le client est n'est pas encore activé .`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, false);
        }

    }

    sentEmailActivationToClient() {

        if (this.customer.clientStatus == 'WAITING') {
            // const strNumber = this.customer.id;
            if (this.customer.id) {

                this.customersService.sendEmailActivation(this.customer.id).subscribe();
                const message = `L'email à envoyer`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, false);
            } else {
                const message = `le client est n'est pas encore activé .`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, true, false);
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

}
