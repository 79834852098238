import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nameRole'
})
export class NameRolePipe implements PipeTransform {

    private roleMap: { [key: string]: string } = {
        'ROLE_COMMERCIAL_REGION_MANAGER': 'COM',
        'ROLE_POS_EK': 'POS',
        'ROLE_CONFORMITY_MANAGER': 'CNF',
        'ROLE_GUEST_ADMIN': 'AdG',
        'ROLE_CREDIT_ANALYST_EK': 'CrA',
        'ROLE_SUPERADMIN': 'SpA',
        'ROLE_BANKER_EK': 'BNQ',
        'ROLE_ADMIN': 'ADM',
        'ROLE_COMMERCIAL_POS_EK': 'CP'
    };
    transform(value: [string, string, string]): string {
        if (!value) {
            return '';
        }

        const [firstName, lastName, role] = value;
        const abbreviatedRole = this.roleMap[role] || '';

        const displayFirstName = firstName || '';
        const displayLastName = lastName ? lastName.toUpperCase() : '';

        return `${displayFirstName}\n${displayLastName} (${abbreviatedRole})`;
    }
}
