export const environment = {
    production: false,
    isMockEnabled: true, //TODO You have to switch this, TO USE THE REAL BACK-END
};

export const ADMIN_CONFIG = {

    apiEndpoint: 'https://apiadmin-cpa-dev.wissal-group.com/',
    wsEndpoint: 'ws://apiadmin-cpa-dev.wissal-group.com/stomp',

    /*      apiEndpoint: 'http://localhost:9090/',
        wsEndpoint: 'ws://localhost:9090/stomp',*/

    title: 'Admin endpoint',
    clientId: 'wissalsys-ecommerce-admin',
    secret: 'XY7kmzoNzl100',
};
